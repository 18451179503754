<template>
  <div style="background-color: #F4F2E4;">
    <section>
      <article style="
          padding-top: 160px;
          border: 1px solid transparent;
          background-color: #F4F2E4;
        ">
        <div
          class="container"
          style="margin: 50px auto; text-align: left"
        >
          <div class="dining_introduction">
            <p class="trekkers_word_style">
              <small>TREKKER'S TAVERN COTTAGES</small>
            </p>
            <h2 style="font-weight: 600; font-size: 2em">
              {{ room_detail.title }}
            </h2>
            <p style="font-weight: 300">
              {{ room_detail.description }}
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
          <div class="room_photos_grid">
            <div>
              <img
                :src="room_detail.images[0]"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div>
              <div
                v-for="image in room_detail.images"
                :key="image"
              >
                <img
                  :src="image"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div>
                <img
                  :src="room_detail.images[1]"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
          <h2 style="font-weight: 600; font-size: 1.5em">
            What this place offers
          </h2>
          <div class="row">
            <div class="col-md-6">
              <ul style="color: grey">
                <div class="row">
                  <div class="col-md-6">
                    <li>
                      <small>
                        <i
                          class="fas fa-shower"
                          style="color: #0E763C; padding-right: 5px"
                        ></i>
                        Bathroom Amenities
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-wifi"
                          style="color: #0E763C; padding-right: 5px"
                        ></i>
                        Free High Speed Wi-Fi Internet
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-tv"
                          style="color: #0E763C; padding-right: 5px"
                        ></i>
                        Satellite TV
                      </small>
                    </li>
                  </div>
                  <div class="col-md-6">
                    <li>
                      <small>
                        <i
                          class="el-icon-phone-outline"
                          style="color: #0E763C; padding-right: 5px"
                        ></i>
                        Room service available
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-swimming-pool"
                          style="color: #0E763C; padding-right: 5px"
                        ></i>
                        Free Swimming Pool Access
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-tshirt"
                          style="color: #0E763C; padding-right: 5px"
                        ></i>
                        Laundry service at a cost
                      </small>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
            <div
              class="col-md-6"
              style="display: flex; justify-content: flex-end"
            >
              <div class="checkout_box">
                <div
                  class="mb-2"
                  style="display: flex; justify-content: space-between"
                >
                  <span style="font-weight: 600; font-size: 1.2em">
                    $
                    {{
                      formatNumber(
                        datesDifferenceResults_value * room_detail.room_price
                      )
                    }}
                  </span>
                  <span style="font-weight: 300; font-size: 1em">
                    /{{ datesDifferenceResults_value }}
                    {{ datesDifferenceResults_value == 1 ? "Night" : "Nights" }}
                  </span>
                </div>

                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  label-position="top"
                  ref="ruleForm"
                  label-width="120px"
                  class="demo-ruleForm"
                >
                  <div style="display: flex">
                    <div class="block">
                      <el-form-item prop="check_in_date">
                        <el-date-picker
                          v-model="ruleForm.check_in_date"
                          type="date"
                          @change="changedValue()"
                          placeholder="CheckIn date"
                          style="width: 100%"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="block">
                      <el-form-item prop="check_out_date">
                        <el-date-picker
                          v-model="ruleForm.check_out_date"
                          type="date"
                          @change="changedValue()"
                          placeholder="CheckOut date"
                          style="width: 100%"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>

                  <div style="display: flex">
                    <div class="block">
                      <el-form-item
                        label="Adults"
                        prop="number_adult_guest"
                      >
                        <el-input-number
                          v-model="ruleForm.number_adult_guest"
                          controls-position="right"
                          style="width: 100%"
                          @change="handleChange"
                          :min="1"
                          :max="2"
                        >
                        </el-input-number>
                      </el-form-item>
                    </div>
                    <div class="block">
                      <el-form-item
                        label="Children"
                        prop="number_children_guest"
                      >
                        <el-input-number
                          v-model="ruleForm.number_children_guest"
                          controls-position="right"
                          style="width: 100%"
                          @change="handleChange"
                          :min="0"
                          :max="3"
                        >
                        </el-input-number>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row mt-4 mb-5">
                    <div class="col-md-12">
                      <el-form-item>
                        <el-button
                          type="primary"
                          @click="submitForm('ruleForm')"
                          class="btn btn-brown btn-md"
                          style="width: 100%"
                        >
                          PROCEED TO CHECKOUT
                          <span class="el-icon-d-arrow-right"></span>
                        </el-button>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table_drawer: false,
      direction: "rtl",
      roomId: this.$route.params.roomId,
      room_detail: {},
      checkInDate: "",
      checkOutDate: "",
      datesDifferenceResults_value: "",
      adults_num: 1,
      children_num: 0,

      options: [
        {
          value: "1",
          label: "1 Guest",
        },
        {
          value: "2",
          label: "2 Guests",
        },
        {
          value: "3",
          label: "3 Guests",
        },
        {
          value: "4",
          label: "4 Guests",
        },
        {
          value: "5",
          label: "5 Guests",
        },
      ],
      value: "",

      rooms: [
        {
          id: "1",
          room_code: "standard",
          title: "Standard Cottage Rooms",
          room_price: 250,
          description:
            "Ideal for Families or long staying guests, the Super Executive Cottage features three bed rooms en-suite, a cosy lounge, separate dining area and a kitchen.",
          images: [
            require("../assets/images/cottages/20220608_151529.jpg"),
            require("../assets/images/cottages/IMG-20191001-WA0133.jpg"),
            require("../assets/images/cottages/20220608_165115.jpg"),
          ],
        },
        {
          id: "3",
          title: "honeymoon",
          room_code: "Honeymoon Cottage Rooms",
          room_price: 80,
          description:
            "Executive Rooms offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
          images: [
            require("../assets/images/cottages/20220608_164704.jpg"),
            require("../assets/images/cottages/20220608_174802.jpg"),
            require("../assets/images/cottages/new/5.jpeg"),
          ],
        },
        {
          id: "2",
          room_code: "twin_family",
          title: "Twin Family Cottage Rooms",
          room_price: 60,
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../assets/images/cottages/20220608_164704.jpg"),
            require("../assets/images/cottages/new/9.jpeg"),
            require("../assets/images/cottages/20220608_165530.jpg"),
          ],
        },
        {
          id: "2",
          room_code: "family",
          title: "Family Cottage House",
          room_price: 60,
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../assets/images/cottages/20220608_133842.jpg"),
            require("../assets/images/cottages/20220608_174802.jpg"),
            require("../assets/images/cottages/20220608_165442.jpg"),
          ],
        },
      ],

      ruleForm: {
        room_name: "",
        room_code: "",
        number_of_rooms: 1,
        room_price: "",
        check_in_date: "",
        check_out_date: "",
        number_adult_guest: "",
        number_children_guest: "",
      },

      rules: {
        check_in_date: [
          {
            type: "date",
            required: true,
            message: "Please pick Check-In Date",
            trigger: "change",
          },
        ],
        check_out_date: [
          {
            type: "date",
            required: true,
            message: "Please pick Check-out Date",
            trigger: "change",
          },
        ],
        number_adult_guest: [
          {
            required: true,
            message: "Please select number of Adults",
            trigger: "change",
          },
        ],
        number_children_guest: [
          {
            required: true,
            message: "Please select number of Children",
            trigger: "change",
          },
        ],
      },
    };
  },

  watch: {
    "$route.params.roomId": {
      handler: function (room) {
        this.getRoomDetails();
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.getRoomDetails();
    this.datesDifferenceResults();
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    datesDifferenceResults() {
      if (
        this.ruleForm.check_in_date != "" &&
        this.ruleForm.check_out_date != ""
      ) {
        const date1 = new Date(`${this.ruleForm.check_in_date}`);
        const date2 = new Date(`${this.ruleForm.check_out_date}`);
        const absDate1 = Math.abs(date1);
        const absDate2 = Math.abs(date2);
        const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
        const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
        const diffDays = ceilDate2 - ceilDate1;
        this.datesDifferenceResults_value = diffDays;
      } else {
        this.datesDifferenceResults_value = 1;
      }
    },

    changedValue() {
      const date1 = new Date(`${this.ruleForm.check_in_date}`);
      const date2 = new Date(`${this.ruleForm.check_out_date}`);
      const absDate1 = Math.abs(date1);
      const absDate2 = Math.abs(date2);
      const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
      const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
      console.log("In: " + ceilDate1);
      console.log("Out: " + ceilDate2);
      if (ceilDate2 < ceilDate1) {
        this.ruleForm.check_out_date = "";

        this.$notify({
          title: "Past Date",
          message: "Check out date can't be lower than Check In date.",
          type: "warning",
        });
      }
      this.datesDifferenceResults();
    },

    handleClose(done) {
      done();
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.room_name = this.room_detail.title;
          this.ruleForm.room_code = this.room_detail.room_code;
          this.ruleForm.room_price =
            this.room_detail.room_price * this.datesDifferenceResults_value;

          localStorage.setItem("room_details", JSON.stringify(this.ruleForm));
          this.$router.push({
            name: "Reservations",
            query: { plan: this.$route.params.roomId },
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleChange(value) {
      console.log(value);
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },

    getRoomDetails() {
      //THE FIND METHOD RETURNS AN OBJECT FROM THE ARRAY.
      this.room_detail = this.rooms.find((room) => {
        return room.id == this.roomId;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.trekkers_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}
.dining_introduction {
  width: 60%;
}

.room_photos_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.room_photos_grid > div {
  height: 400px;
}

.room_photos_grid > div:first-child {
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.room_photos_grid > div:first-child img {
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.room_photos_grid > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.room_photos_grid > div:nth-child(2) > div {
  height: 100%;
  width: 100%;
  border-radius: 5px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.room_photos_grid > div:nth-child(2) > div img {
  height: 195px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

ul li {
  margin-bottom: 15px;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

.btn-brown {
  background-color: #0e763c;
  color: white;
  width: 100%;
}
.btn-view-more {
  background-color: white;
  border: 1px solid #0e763c;
  width: 250px;
}

.rooms {
  background-color: #f4f2e4;
}

.rooms_grid {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  border-radius: 5px;
}

.rooms_grid > div {
  text-align: left;
  padding: 20px;
  border-radius: 5px;
}

.rooms_grid > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.rooms_grid .rooms_image img {
  height: 260px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.rooms_grid ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.checkout_box {
  width: 60%;
  background-color: #e5dea5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .checkout_box {
    width: 80%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .checkout_box {
    width: 80%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .room_photos_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .checkout_box {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .room_photos_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .checkout_box {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}
</style>


<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>